/* #app {
  max-width: 850px;
  margin: 0 auto;
} */

body {
    margin:0;
    padding:0;
}

nav {
  padding: 20px 0 20px 0;
}

#transcript-div, #answer-div {
  overflow-y: auto;
  height: 100px;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}


/* Typing animation */
.typing::after {
  content: "|";
  animation: typing 1s infinite;
}

@keyframes typing {
  0% {
      opacity: 0;
  }

  20% {
      opacity: 1;
  }

  100% {
      opacity: 0;
  }
}

/* Thinking animation */
.thinking::after {
  content: "";
  border-radius: 50%;
  display: inline-block;
  border: 2px solid #000;
  border-top-color: transparent;
  animation: thinking 1s infinite;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

@keyframes thinking {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

/* Chat Message */

.outbound {
  border-radius: 0.5rem 0.5rem 0;
  background-color: #1976D2;
}

.inbound {
  border-radius: 0.5rem 0.5rem 0.5rem 0;
  background-color: #f3f3f3;
}
